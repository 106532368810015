import React from 'react';
import AxiosRequest from '../apis';
import { message } from 'antd';
import { navigate } from 'gatsby';

const RequestAdminGet = ({ reqParams, requestCount, responseDataHandle }) => {
  // get 请求 获取admin list
  const method = 'get';
  const reqObject = {};
  const api = 'adminer/v1/admin/1';

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};

RequestAdminGet.defaultProps = {
  reqParams: {},
  requestCount: 0,
  responseDataHandle: (respObj) => {
    if (Object.keys(respObj).includes('code') && respObj.code === 1000) {
      console.log('Success');
    } else {
      message.info('请登陆...');
      navigate('/login');
    }
  },
};

export { RequestAdminGet };
