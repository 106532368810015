import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined, HomeOutlined } from '@ant-design/icons';

import { Link, navigate } from 'gatsby';

const DropdownMenu = () => {
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link className="nav-link" to="/">首页</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link className="nav-link" to="/logistic">快递</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link className="nav-link" to="/storemanage">微店</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link className="nav-link" to="/navstatistic">统计</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link className="nav-link" to="/manage">管理</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="5"><Link className="nav-link" to="/login">退出</Link></Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button className="ant-dropdown-link">
        <div>Menu <DownOutlined /></div>
      </Button>
    </Dropdown>
  );
}

const Header = () => {
  return (
    <div className="navbar bg-base-200">
      <div className="flex-none m-4">
        <Link className="nav-link" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        </Link>
      </div>
      <div className="flex-1 m-2">
        <a className="btn btn-ghost normal-case text-xl">展望订单</a>
      </div>
      <div className="flex-none">
        <DropdownMenu key="1"></DropdownMenu>
      </div>
    </div>
  )
}

export default Header;
